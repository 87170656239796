


























import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseListMasterType } from "@interface/master.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { masterTypeService } from "@service/master-type.service";
import { IOption } from "@interface/common.interface";
import Vue from "vue";

export default Vue.extend({
  name: "CSelectTermOfPayment",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dtOpt: [] as IOption[],
      loading: false,
    };
  },
  mounted() {
    this.getListTOP();
  },
  methods: {
    getListMasterType(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster(params);
    },
    async getListTOP(): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListMasterType({name: "TOP"});
        const opts = res.map(x => {
          return { key: this.getLabel(x.value), value: x.value };
        });
        this.dtOpt = opts.map(x => { return { key: x.key, value: x.value };});
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    getLabel(top: string): string {
      if (top === "0") {
        return "lbl_cash_on_delivery";
      } else if (top === "-1") {
        return "lbl_cash_on_advance";
      } else {
        return top;
      }
    },
    onSelect(e: string): void {
      this.$emit("input", e);
      this.$emit("on-select", { value: e });
      this.$emit("change", e);
    }
  }
});
