var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      staticStyle: { width: "100%" },
      attrs: {
        value: _vm.value,
        placeholder: _vm.$t("lbl_choose"),
        "allow-clear": "",
        disabled: _vm.disabled,
        loading: _vm.loading,
        "dropdown-match-select-width": false
      }
    },
    _vm._l(_vm.dtOpt, function(item) {
      return _c(
        "a-select-option",
        {
          key: item.value,
          attrs: { value: item.value },
          on: {
            click: function() {
              return _vm.onSelect(item.value)
            }
          }
        },
        [
          _c(
            "a-tooltip",
            [
              _c("template", { slot: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      item.value === "0"
                        ? _vm.$t(item.key)
                        : (item.value === "-1"
                            ? _vm.$t("lbl_cash_in_advance")
                            : _vm.$t("lbl_top_in_day", {
                                value: item.value
                              })) || "-"
                    ) +
                    " "
                )
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    item.value === "0"
                      ? _vm.$t(item.key)
                      : (item.value === "-1"
                          ? _vm.$t("lbl_cash_in_advance")
                          : _vm.$t("lbl_top_in_day", { value: item.value })) ||
                          "-"
                  ) +
                  " "
              )
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }